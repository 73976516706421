import { defineStore } from 'pinia'
export const useSearchStore = defineStore({
  id: 'searchStore',
  state: () => ({
    unslugify: <Unslugify[]>[],
    searchInput: '',
    searchType: '',
    aresType: '',
    aresKey: <string | number>'',
    countries: <Countries[]>[],
    hoverAccoId: '',
    correctListerRoute: <CorrectListerRoute>{}
  }),
  actions: {
    async setUnslugify() {
      const config = useRuntimeConfig()
      const lang = useNuxtApp().$i18n.locale.value
      this.unslugify = await $fetch<Unslugify[]>(`${config.public.ARES_API}/unslugify`, {
        method: 'POST',
        body: {
          lang
        }
      })
    },
    async setCountries() {
      const config = useRuntimeConfig()
      const lang = useNuxtApp().$i18n.locale.value
      this.countries = await $fetch<Countries[]>(`${config.public.ARES_API}/getRegio`, {
        method: 'POST',
        body: {
          lang
        }
      })
    },
    setSearchFromRoute() {
      this.searchInput = ''
      this.searchType = ''
      this.aresType = ''
      this.aresKey = ''
      const route = useNuxtApp().$router.currentRoute.value
      let searchSlug:string | null = null
      if (Array.isArray(route.params.slug)) {
        let slugArr:string[] = route.params.slug
        slugArr = slugArr.filter(x => x) // Remove empty values from array (incase of trailing slash in path)
        switch (slugArr.length) {
          case 2: // Slug: /region/placename
            searchSlug = slugArr[1]
            this.searchType = 'placename'
            break
          case 1: // Slug: /region
            searchSlug = slugArr[0]
            this.searchType = 'region'
            break
        }
      } else if (route.params.country) {
        searchSlug = route.params.country.toString()
        this.searchType = 'country'
      }
      if (searchSlug) {
        const unslugFound = this.unslugify.find(x => x.slug === searchSlug)
        if (unslugFound) {
          this.searchInput = unslugFound.unslug
          this.aresType = unslugFound.aresType
          this.aresKey = unslugFound.aresKey
        } else {
          // Redirect to 404
          throw createError({
            statusCode: 404,
            statusMessage: 'Page Not Found'
          })
        }
      }
    },
    setHoverAccoId(accoId:string) {
      this.hoverAccoId = accoId
    },
    setCorrectListerRoute(routes:CorrectListerRoute) {
      this.correctListerRoute = routes
    }
  }
})
