import { defineStore } from 'pinia'
export const csUserStore = defineStore({
  id: 'csUser',
  state: () => ({
    csEncId: '',
    csUser: ''
  }),
  actions: {
    setCsEncId(value: string) {
      this.csEncId = value
    },
    setCsUser(value: string) {
      this.csUser = value
    },
    logout() {
      const cookieCsEncId = useCookie('csEncId')
      const cookieCsUser = useCookie('csUser')
      const cookieIncludeTestAccos = useCookie('csIncludeTestAccos')
      cookieCsEncId.value = ''
      cookieCsUser.value = ''
      cookieIncludeTestAccos.value = 'no'
      this.csEncId = ''
      this.csUser = ''
    }
  },
  getters: {
    showCs(): boolean {
      return !!this.csEncId
    }
  }
})
