import { useSearchStore } from '@/store/search'
export default defineNuxtPlugin((nuxtApp) => {
  // nuxtApp.hook('i18n:beforeLocaleSwitch', () => {
  //   console.log('Before locale switch', nuxtApp)
  // })
  // Refresh translated store states when locale is switched
  nuxtApp.hook('i18n:localeSwitched', () => {
    const searchStore = useSearchStore()
    searchStore.setUnslugify()
    searchStore.setCountries()
  })
})
