<template>
  <div :class="containerClass">
    <slot />
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  slider: {
    default: false,
    type: Boolean
  },
  fluid: {
    default: false,
    type: Boolean
  }
})
const containerClass = computed(() => {
  if (props.fluid) {
    return 'px-2'
  } else {
    return props.slider ? 'container-slider' : 'max-w-[1200px] mx-auto px-2'
  }
})
</script>

<style lang="postcss" scoped>
.container-slider{
  @apply mx-auto xl:max-w-[1193px] xl:px-2;
  @media (min-width: 1670px){
    @apply max-w-[1580px];
  }
}
</style>
