import { defineStore } from 'pinia'
export const useLikeStore = defineStore({
  id: 'likeStore',
  state: () => ({
    likes: <string[]>[],
    animate: false
  }),
  actions: {
    toggleLike(accoId: string) {
      if (this.likes.includes(accoId)) {
        this.likes = this.likes.filter(x => x !== accoId)
      } else {
        this.likes.push(accoId)
        // Run heartbeat animation in navbar
        this.animate = true
        setTimeout(() => {
          this.animate = false
        }, 1000)
      }
      const cookieExpireTimeSec = 60 * 60 * 24 * 30 * 3 // Expires after 90 days
      const cookieLikes = useCookie('likes', {
        maxAge: cookieExpireTimeSec
      })
      cookieLikes.value = this.likes.join(',')
    },
    setLikes(accoIds: string[]) {
      this.likes = accoIds
    }
  },
  getters: {
    totalLikes(): number {
      return this.likes.length
    }
  }
})
