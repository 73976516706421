import { useUrlParamsStore } from '@/store/urlParams'
export default defineNuxtRouteMiddleware((to) => {
  const urlParamsStore = useUrlParamsStore()
  if (!to.path.startsWith('/nl/vakantiehuis') &&
      !to.path.startsWith('/en/holiday-home') &&
      !to.path.startsWith('/de/ferienhaus') &&
      !to.path.startsWith('/fr/maison-de-vacances')
  ) {
    urlParamsStore.reset()
  }
})
