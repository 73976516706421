import { defineStore } from 'pinia'
export const useAccoStore = defineStore({
  id: 'accoStore',
  state: () => ({
    accos: <Acco[]>[],
    arrivalDate: <Date | null>null,
    departureDate: <Date | null>null,
    from: 0,
    perPage: 10,
    totalResults: -1,
    markers: <Marker[]>[],
    update: false,
    view: 'list',
    adults: 0,
    children: 0,
    babies: 0,
    pets: 0,
    minBedrooms: 0,
    minBathrooms: 0,
    minStars: 0,
    minRating: 0,
    noPets: false,
    companyVfy: false,
    priceMin: 0,
    priceMax: 3500,
    showLastMinutes: false,
    pricerange: {
      min: 0,
      max: 3500,
      type: 'night'
    } as PriceRange,
    sauna: false,
    hottub: false,
    spa: false,
    pool: false,
    privatpool: false,
    garden: false,
    gardenEnclosed: false,
    terrace: false,
    balcony: false,
    parking: false,
    wifi: false,
    washingMachine: false,
    stove: false,
    tumbleDryer: false,
    airco: false,
    tv: false,
    eCarCharging: false,
    dishwasher: false,
    oven: false,
    bbq: false,
    childrenBed: false,
    childrenChair: false,
    detached: false,
    villa: false,
    mansion: false,
    castle: false,
    chalet: false,
    farmhouse: false,
    bungalow: false,
    holidayHome: false,
    apartment: false,
    penthouse: false,
    glampingTent: false,
    houseboat: false,
    nearSkiSlope: false,
    nearSea: false,
    nearLake: false,
    onHolidayPark: false,
    nearSkibus: false,
    maxKmFromSkilift: 0,
    maxKmFromGolfCourse: 0,
    searchCenter: {
      lat: 0,
      lng: 0
    } as LatLng,
    searchBounds: {
      south: 0,
      west: 0,
      north: 0,
      east: 0
    } as Bounds,
    paginationAction: false,
    diffDays: 3,
    saveBoundsInUrlParams: false,
    zoomLevel: 0,
    useStrictBounds: false,
    openFilters: false
  }),
  actions: {
    newAccos(newAccos: Acco[]) {
      this.accos = newAccos
      if (import.meta.client) {
        setTimeout(() => {
          document?.getElementById('lister')?.scrollIntoView({ behavior: 'smooth' })
        }, 1000)
      }
    },
    clearAccos() {
      this.accos = []
      // this.totalResults = 0 mag niet ivm pagination
    },
    resetPagination() {
      this.from = 0
      this.totalResults = 0
    },
    setTotalResults(total:number) {
      this.totalResults = total
    },
    setUpdate(state: boolean) {
      this.update = state
    },
    setView(view: string) {
      this.view = view
      if (view === 'list') {
        this.searchBounds = {
          south: 34.82202822889615,
          west: -19.187923124999998,
          north: 58.567747676682366,
          east: 30.909733125
        }
      }
    },
    setPriceRange(priceRange: PriceRange) {
      this.pricerange = priceRange
    },
    setSearchCenter(latLng: LatLng) {
      this.searchCenter = latLng
    },
    setSearchBounds(bounds: Bounds) {
      this.searchBounds = bounds
    },
    setMarkers(markers: Marker[]) {
      this.markers = markers
    },
    setPaginationAction(state: boolean) {
      this.paginationAction = state
    },
    setDiffDays(days: number) {
      this.diffDays = days
    },
    setSaveBoundsInUrlParams(state: boolean) {
      this.saveBoundsInUrlParams = state
    },
    setZoomLevel(zoom: number) {
      this.zoomLevel = zoom
    },
    setUseStrictBounds() {
      this.useStrictBounds = true
      setTimeout(() => {
        this.useStrictBounds = false
      }, 3000)
    }
  },
  getters: {
    totalAccos(): number {
      return this.accos.length
    }
  }
})
