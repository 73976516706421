import { defineStore } from 'pinia'
import { useUrlSearchParams } from '@vueuse/core'
const urlParams = useUrlSearchParams('history', {
  removeNullishValues: true,
  removeFalsyValues: true,
  write: false
})
export const useUrlParamsStore = defineStore({
  id: 'urlParamsStore',
  state: () => ({
    activeFilterGeneral: false,
    activeFilterPrice: false,
    activeFilterFacilities: false,
    activeFilterType: false,
    activeFilterLocation: false
  }),
  actions: {
    set(name: string, value: string | string[]) {
      urlParams[name] = value
    },
    get(name: string) {
      return urlParams[name]
    },
    urlParams() {
      return urlParams
    },
    reset() {
      urlParams.arrival = ''
      urlParams.departure = ''
      urlParams.priceMin = ''
      urlParams.priceMax = ''
      urlParams.adults = ''
      urlParams.children = ''
      urlParams.babies = ''
      urlParams.pets = ''
      urlParams.bedrooms = ''
      urlParams.bathrooms = ''
      urlParams.rating = ''
      urlParams.noPets = ''
      urlParams.lastMinutes = ''
      urlParams.facility = ''
      urlParams.detached = ''
      urlParams.types = ''
      urlParams.location = ''
      urlParams.maxKmFromSkilift = ''
      urlParams.maxKmFromGolfCourse = ''
      urlParams.sort = ''
      urlParams.page = ''
      urlParams.ddev = ''
    },
    setActiveFilter(name:'activeFilterGeneral'|'activeFilterPrice'|'activeFilterFacilities'|'activeFilterType'|'activeFilterLocation', value: boolean) {
      this[name] = value
    },
    filtersActive() {
      return this.activeFilterGeneral || this.activeFilterPrice || this.activeFilterFacilities || this.activeFilterType || this.activeFilterLocation
    }
  }
})
