<template>
  <img
    :src="`/icons/${iconName}.svg`"
    :class="{white,green,red,grey}"
    class="inline"
    :alt="name"
    :width="size"
    :height="size"
  >
</template>

<script lang="ts" setup>
const props = defineProps({
  name: {
    type: String,
    required: true
  },
  state: {
    type: String,
    default: ''
  },
  size: {
    type: String,
    default: '24'
  },
  white: Boolean,
  green: Boolean,
  red: Boolean,
  grey: Boolean
})
const iconName = computed(() => {
  switch (props.name) {
    case 'arrow':
      return `${props.name}${props.state ? '-' + props.state : '-right'}`
    case 'chevron':
      return `${props.name}${props.state ? '-' + props.state : '-right'}`
    default:
      return `${props.name}${props.state ? '-' + props.state : ''}`
  }
})
</script>

<style lang="scss" scoped>
// filter gererator https://codepen.io/sosuke/pen/Pjoqqp
.white{
  filter: invert(98%) sepia(100%) saturate(0%) hue-rotate(335deg) brightness(111%) contrast(102%);
  background-color: transparent !important;
}
.green{
  filter: invert(74%) sepia(23%) saturate(1137%) hue-rotate(119deg) brightness(88%) contrast(81%);
  background-color: transparent !important;
}
.red{
  filter: invert(32%) sepia(87%) saturate(6648%) hue-rotate(333deg) brightness(89%) contrast(96%);
  background-color: transparent !important;
}
.grey{
  filter: invert(45%) sepia(25%) saturate(219%) hue-rotate(182deg) brightness(92%) contrast(88%);
  background-color: transparent !important;
}
</style>
