<template>
  <Component :is="props.type" :class="{white,center,bold,green,red,details,header,hero,price,greenDark,'border-b':border,'txt-shadow':shadow}">
    <slot />
  </Component>
</template>

<script lang="ts" setup>
const props = defineProps({
  type: {
    default: 'p',
    type: String
  },
  shadow: {
    type: Boolean
  },
  white: {
    type: Boolean
  },
  center: {
    type: Boolean
  },
  bold: {
    type: Boolean
  },
  green: {
    type: Boolean
  },
  red: {
    type: Boolean
  },
  details: {
    type: Boolean
  },
  header: {
    type: Boolean
  },
  border: {
    type: Boolean
  },
  hero: {
    type: Boolean
  },
  price: {
    type: Boolean
  },
  greenDark: {
    type: Boolean
  }
})
</script>

<style lang="postcss" scoped>
h1{
  @apply font-sen font-bold text-[2.5rem] md:text-[3rem];
  &.hero {
    @apply text-[2.2rem] md:text-[3.5rem];
  }
}
h2{
  @apply font-sen font-bold text-[1.75rem] md:text-[2.25rem];
  &.details{
    @apply pb-1 mb-1 mt-8 text-[1.5rem] md:text-[1.75rem];
  }
}
h3{
  @apply font-sen font-bold text-[1.5rem] md:text-[1.75rem];
}
h4{
  @apply font-sen font-bold text-[1.2rem] md:text-[1.25rem];
}
h5{
  @apply font-sen font-bold text-[1.2rem] md:text-[1.25rem];
}
h6{
  @apply font-sen font-bold text-[1.2rem] md:text-[1.25rem];
}
p{
  @apply font-notosans text-[16px] whitespace-pre-line;
  &.details{
    @apply leading-[1.5];
  }
  &.header{
    @apply font-notosans text-[20px] md:text-[24px];
  }
  &.price{
    @apply text-[1.25rem];
  }
}
.white{
  @apply text-white;
}
.txt-shadow{
  @apply [text-shadow:_0_1px_7px_rgb(0_0_0_/_75%)] md:[text-shadow:_0_2px_8px_rgb(0_0_0_/_80%)];
}
.center{
  @apply text-center;
}
.bold{
  @apply font-bold;
}
.green{
  @apply text-green;
}
.red{
  @apply text-red;
}
.greenDark{
  @apply text-[#309C8C];
}
</style>
