// Middleware anti-duplicate pages
export default defineNuxtRouteMiddleware(({ path, query, hash }) => {
  // console.log('-----------------------------------------------------') // eslint-disable-line no-console
  // if (to.path === '/nl/') {
  //   // console.log('path === /nl/') // eslint-disable-line no-console
  //   console.log('query', to.query) // eslint-disable-line no-console
  //   console.log('hash', to.hash) // eslint-disable-line no-console
  // }
  // const nuxtApp = useNuxtApp()
  // Skip middleware on initial client load
  // if (import.meta.client && nuxtApp.isHydrating && nuxtApp.payload.serverRendered) {
  //   console.log('SKIPPING') // eslint-disable-line no-console
  //   return
  // }
  // if (!to.path) {
  //   // console.error('Middleware redirect.global.ts: path is empty') // eslint-disable-line no-console
  //   return
  // }
  // Root redirect
  // if (to.path === '/') {
  // let headers = null
  // try {
  //   headers = useRequestHeaders()
  // } catch (e) {
  //   console.error('Middleware redirect.global.ts: headers could not be parsed') // eslint-disable-line no-console
  //   console.error(e) // eslint-disable-line no-console
  // }
  // if (headers) {
  //   const findI18nCookie = headers.cookie ? headers.cookie.split(';').find(x => x.trim().startsWith('i18n_redirected')) : null
  //   // // Redirect to language saved in i18n cookie
  //   // const i18nCookie = useCookie('i18n_redirected')
  //   // const nuxtApp = useNuxtApp()
  //   if (findI18nCookie) {
  //     switch (findI18nCookie) {
  //       case 'nl':
  //         return nuxtApp.runWithContext(() => navigateTo('/nl/', { redirectCode: 301 }))
  //       case 'de':
  //         return nuxtApp.runWithContext(() => navigateTo('/de/', { redirectCode: 301 }))
  //       case 'fr':
  //         return nuxtApp.runWithContext(() => navigateTo('/fr/', { redirectCode: 301 }))
  //       default:
  //         return nuxtApp.runWithContext(() => navigateTo('/en/', { redirectCode: 301 }))
  //     }
  //   } else {
  //   // Redrect to browser language
  //     const browserLang = headers['accept-language'].split(',')[0].toLocaleLowerCase().substring(0, 2)
  //     if (browserLang) {
  //       switch (browserLang) {
  //         case 'nl':
  //           return nuxtApp.runWithContext(() => navigateTo('/nl/', { redirectCode: 301 }))
  //         case 'de':
  //           return nuxtApp.runWithContext(() => navigateTo('/de/', { redirectCode: 301 }))
  //         case 'fr':
  //           return nuxtApp.runWithContext(() => navigateTo('/fr/', { redirectCode: 301 }))
  //         default:
  //           return nuxtApp.runWithContext(() => navigateTo('/en/', { redirectCode: 301 }))
  //       }
  //     } else {
  //       // Fallback to EN
  //       return nuxtApp.runWithContext(() => navigateTo('/en/', { redirectCode: 301 }))
  //     }
  //   }
  // }

  // Check if i18n cookie is set

  // console.log('useCookie -> i18n_redirected', i18nCookie.value || 'LEEG') // eslint-disable-line no-console
  // const browserLang1 = headers['accept-language'].split(',')[0].toLocaleLowerCase().substring(0, 2)
  // console.log('headers["accept-language"] -> browserLang', browserLang1 || 'LEEG') // eslint-disable-line no-console
  // if (i18nCookie.value) {
  //   switch (i18nCookie.value) {
  //     case 'nl':
  //       return navigateTo('/nl/', { redirectCode: 301 })
  //     case 'de':
  //       return navigateTo('/de/', { redirectCode: 301 })
  //     case 'fr':
  //       return navigateTo('/fr/', { redirectCode: 301 })
  //     default:
  //       return navigateTo('/en/', { redirectCode: 301 })
  //   }
  // } else {
  //   console.log('GEEN i18n cookie gevonden') // eslint-disable-line no-console
  //   return navigateTo('/en/', { redirectCode: 301 })
  // }
  // const i18nCookie = useCookie('i18n_redirected')
  // console.log('HIERRRR -> i18nCookie useCookie', i18nCookie.value || 'LEEG') // eslint-disable-line no-console
  // const findI18nCookie = headers.cookie.split(';').find(x => x.trim().startsWith('i18n_redirected'))
  // // console.log('HIERRRR -> findI18nCookie', findI18nCookie || 'LEEG') // eslint-disable-line no-console
  // if (findI18nCookie) {
  //   const i18nCookie = findI18nCookie.split('=')[1]
  //   switch (i18nCookie) {
  //     case 'nl':
  //       return navigateTo('/nl/', { redirectCode: 301 })
  //     case 'de':
  //       return navigateTo('/de/', { redirectCode: 301 })
  //     case 'fr':
  //       return navigateTo('/fr/', { redirectCode: 301 })
  //     default:
  //       return navigateTo('/en/', { redirectCode: 301 })
  //   }
  // }
  // // No i18n cookie? Then redirect to browserlanguage
  // const browserLang = headers['accept-language'].split(',')[0].toLocaleLowerCase().substring(0, 2)
  // // console.log('HIERRRR -> browserLang', browserLang) // eslint-disable-line no-console
  // switch (browserLang) {
  //   case 'nl':
  //     return navigateTo('/nl/', { redirectCode: 301 })
  //   case 'de':
  //     return navigateTo('/de/', { redirectCode: 301 })
  //   case 'fr':
  //     return navigateTo('/fr/', { redirectCode: 301 })
  //   default:
  //     return navigateTo('/en/', { redirectCode: 301 })
  // }
  // }
  if (!path || path === '/') { return }
  // Trailing slash: Search pages and acco pages
  if (path.startsWith('/nl/vakantiehuis') ||
      path.startsWith('/en/holiday-home') ||
      path.startsWith('/de/ferienhaus') ||
      path.startsWith('/fr/maison-de-vacances') ||
      path.startsWith('/nl/last-minute') ||
      path.startsWith('/en/last-minute') ||
      path.startsWith('/de/last-minute') ||
      path.startsWith('/fr/derniere-minute')) {
    let accoNr = path.split('-').pop() || ''
    accoNr = accoNr.replace('/', '')
    const regex = /a[0-9]{1,10}$/ // AccoNr with prefixed 'a' like a1234 (upto 10 digits behind a)
    if (regex.test(accoNr)) {
      // ACCO PAGE: Remove trailing slash
      if (!path.endsWith('/')) { return }
      const nextPath = path.replace(/\/$/, '')
      const nextRoute = { path: nextPath, query, hash }
      return navigateTo(nextRoute, { redirectCode: 301 })
    } else {
      // SEARCH PAGE: Add trailing slash
      if (path.endsWith('/')) { return }
      const nextPath = `${path}/`
      const nextRoute = { path: nextPath, query, hash }
      return navigateTo(nextRoute, { redirectCode: 301 })
    }
  }
  // Trailing slash: Other pages
  if (path === '/nl' ||
      path === '/en' ||
      path === '/de' ||
      path === '/fr' ||
      path === '/nl/partners' ||
      path === '/en/partners' ||
      path === '/de/partners' ||
      path === '/fr/partenaires' ||
      path === '/news/nl' ||
      path === '/news/en' ||
      path === '/news/de' ||
      path === '/news/fr') {
    // Add trailing slash
    const nextPath = `${path}/`
    const nextRoute = { path: nextPath, query, hash }
    return navigateTo(nextRoute, { redirectCode: 301 })
  } else {
    if (path === '/nl/') { return }
    if (path === '/en/') { return }
    if (path === '/de/') { return }
    if (path === '/fr/') { return }
    if (path === '/nl/partners/') { return }
    if (path === '/en/partners/') { return }
    if (path === '/de/partners/') { return }
    if (path === '/fr/partenaires/') { return }
    if (path === '/news/nl/') { return }
    if (path === '/news/en/') { return }
    if (path === '/news/de/') { return }
    if (path === '/news/fr/') { return }
    if (!path.endsWith('/')) { return }
    // Remove triling slash
    const nextPath = path.replace(/\/$/, '')
    const nextRoute = { path: nextPath, query, hash }
    return navigateTo(nextRoute, { redirectCode: 301 })
  }
})
