import revive_payload_client_4sVQNw7RlN from "/home/vfy/website-git-prod/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/vfy/website-git-prod/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/vfy/website-git-prod/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/home/vfy/website-git-prod/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/home/vfy/website-git-prod/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/vfy/website-git-prod/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/vfy/website-git-prod/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/home/vfy/website-git-prod/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/home/vfy/website-git-prod/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/vfy/website-git-prod/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/vfy/website-git-prod/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_PPNordJOp3 from "/home/vfy/website-git-prod/node_modules/nuxt-splide/dist/runtime/plugin.mjs";
import sentry_client_VdOeuw25Mb from "/home/vfy/website-git-prod/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/home/vfy/website-git-prod/.nuxt/sentry-client-config.mjs";
import nuxt_plugin_6wEQMY3tee from "/home/vfy/website-git-prod/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import plugin_tbFNToZNim from "/home/vfy/website-git-prod/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_KlVwwuJRCL from "/home/vfy/website-git-prod/node_modules/@storyblok/nuxt/dist/runtime/plugin.mjs";
import titles_dw2T9lEw4h from "/home/vfy/website-git-prod/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import defaults_aRl2YLnTcb from "/home/vfy/website-git-prod/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.js";
import siteConfig_izaWKZ8rEu from "/home/vfy/website-git-prod/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_Uk9bcXDHeN from "/home/vfy/website-git-prod/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import switch_locale_path_ssr_8BKffvaEi5 from "/home/vfy/website-git-prod/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.mjs";
import i18n_yfWm7jX06p from "/home/vfy/website-git-prod/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import googleMaps_yobp94ocoP from "/home/vfy/website-git-prod/plugins/googleMaps.ts";
import i18n_VfGcjrvSkj from "/home/vfy/website-git-prod/plugins/i18n.ts";
import silentbox_If7pnWNEDb from "/home/vfy/website-git-prod/plugins/silentbox.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_PPNordJOp3,
  sentry_client_VdOeuw25Mb,
  sentry_client_config_o34nk2sJbg,
  nuxt_plugin_6wEQMY3tee,
  plugin_tbFNToZNim,
  plugin_KlVwwuJRCL,
  titles_dw2T9lEw4h,
  defaults_aRl2YLnTcb,
  siteConfig_izaWKZ8rEu,
  inferSeoMetaPlugin_Uk9bcXDHeN,
  switch_locale_path_ssr_8BKffvaEi5,
  i18n_yfWm7jX06p,
  googleMaps_yobp94ocoP,
  i18n_VfGcjrvSkj,
  silentbox_If7pnWNEDb
]