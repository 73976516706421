import validate from "/home/vfy/website-git-prod/node_modules/nuxt/dist/pages/runtime/validate.js";
import redirect_45global from "/home/vfy/website-git-prod/middleware/redirect.global.ts";
import reset_45url_45query_45global from "/home/vfy/website-git-prod/middleware/reset-url-query.global.ts";
import suppress_45server_45messages_45global from "/home/vfy/website-git-prod/middleware/suppress-server-messages.global.ts";
import manifest_45route_45rule from "/home/vfy/website-git-prod/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  redirect_45global,
  reset_45url_45query_45global,
  suppress_45server_45messages_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}