// middleware/suppress-server-messages.global.ts
export default defineNuxtRouteMiddleware(() => {
  const originalError = console.error // eslint-disable-line no-console
  // eslint-disable-next-line no-console
  console.error = (...args) => {
    if (args[0] && typeof args[0] === 'string' && args[0].includes('NODE_TLS_REJECT_UNAUTHORIZED')) {
      return
    }
    originalError.apply(console, args)
  }
})
